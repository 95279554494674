var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4f2a7e4a7f047e622cb315a9bcbe7b324a0ae9c4"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://28db771cd82a4306b62670a610aeac25@o27727.ingest.sentry.io/6019702',
  environment: process.env.ENVIRONMENT || process.env.NEXT_PUBLIC_ENVIRONMENT,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  // Common non codebase error sources
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /solutions\.invocacdn\.com\//i,
    /bat\.bing\.com\//i,
    /cdn\.nmgassets\.com\//i,
    /googleads/i,
    /amazonaws\.com\/b2bjsstore\/b\/1VN080HWW86J\/reb2b\.js\.gz/i,
    /analytics\.tiktok\.com/i,
    /cdn\.taboola\.com/i,
  ],
});
